import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'

// Vue.prototype.$api = "https://v2.api.zhongshengrunde.com/"
// Vue.prototype.$api = "https://www.zhongshengrunde.com/"
// axios.defaults.baseURL = "https://www.zhongshengrunde.com/"
axios.defaults.baseURL = "https://v2.api.zhongshengrunde.com/"
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
