import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'oneStop',
    component: () => import(/* webpackChunkName: "about" */ '../views/one-stop/index.vue')
  },
  {
    path: '/stopDetail',
    name: 'stopDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/one-stop/detail.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
